import { Injectable } from '@angular/core';
import { DocumentServiceInjected } from '@profis-engineering/pe-ui-common/services/document.common';

export abstract class DocumentServiceBaseCW extends DocumentServiceInjected {
}

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends DocumentServiceBaseCW {
    public override setBaseService(baseService: DocumentServiceBaseCW) {
        super.setBaseService(baseService);
    }
}
