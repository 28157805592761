export enum DesignCodeList {
    ApplicationTypes,
    FasteningTechnologies,
    InspectionTypes,
    DesignCodeList,
    ConcreteMixes,
    BaseMaterials,
    EdgeReinforcements,
    ShearConditions,
    TensionConditions,
    AnchorFamilies,
    AnchorTypes,
    AnchorSizes,
    EmbedmentOptimizationTypes,
    AnchorEmbedmentDepths,
    AnchorFilterGroup,
    AnchorFilter,
    AnchorChannelFamilyGroup,
    AnchorChannelFamily,
    AnchorChannelLength,
    AnchorChannelEmbedmentDepth,
    RebarPlate,
    PlateShapes,
    LoadTypes,
    SeismicShearTypes,
    SeismicTensionTypes,
    BoltFamilies,
    BoltSizes,
    BoltLengths,
    LipStrengthClips,
    StandoffTypes,
    LoadTolerances,
    LoadTolerancesY,
    LoadTolerancesZ,
    GenericRebars,
    NumberOfPlates,
    KBNumberControl,
    KBNumberRegion,
    LoadCombinationEquation,
    LoadCombinationFactor,
    DrillingMethods,
    InstallationDirections,
    HoleConditions,
    HoleTypes,
    TorquingTypes,
    LongitudinalShearCalculation,
    GroutTypes,
    ConcreteReinforcements,
    BondConditions,
    StandOffDesignMethods,
    SeismicCategoryTypes,
    SeismicDesignTypes,
    SeismicDisplacementTypes,
    AvailabilityRegionLinks
}
