<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.Title')"></pe-modal-header>

<pe-modal-body>
<div [innerHtml]="translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.Intro')"></div>
<br>
<div *ngIf="infoLink">
    <span [innerHtml]="translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.GuideLink')"></span>&nbsp;<a [href]="infoLink" target="_blank">link</a>
    <br>
    <br>
</div>

<div [innerHtml]="translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.Message')"></div>

<br>
<div [innerHtml]="translate('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.ContactMessage')"></div>

<div *ngIf="technicalContact || supplyContact">
    <div *ngIf="technicalContact">
        <span [innerHtml]="translateContact('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.TechnicalContact', 'technicalContactMail', technicalContact)"></span>
    </div>
    <div *ngIf="supplyContact">
        <span [innerHtml]="translateContact('Agito.Hilti.CW.Navigation.TabProduct.AnchorChannel.ShowFullPortfolio.Popup.SupplyContact', 'supplyContactMail', supplyContact)"></span>
    </div>
</div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
