import {
    Component, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
    IAddEditDesignComponent
} from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

import { Constants } from '../../entities/constants';
import { Design, ICalculationResult } from '../../entities/design';
import {
    NewDesignRequest
} from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Requests';
import {
    ApplicationTypes,
    DesignMethodGroups, DesignStandards,
    DesignSubTypes
} from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { AppSettingsHelper } from '../../helpers/app-settings-helper';
import { toCwUnit } from '../../helpers/unit-helper';
import { CalculationService } from '../../services/calculation.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { IMethodInputsData } from '../method-inputs/method-inputs.component';
import { IUnitlInputsData } from '../unit-inputs/unit-inputs.component';
import { IDesignInfoCw as IDesignInfo } from '../../entities/design-info-cw';

@Component({
    templateUrl: './add-edit-design.component.html',
    styleUrls: ['./add-edit-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AddEditDesignComponent implements IAddEditDesignComponent, OnInit, OnChanges {

    @Input()
    public designInfo?: IDesignInfo;

    @Input()
    public selectedRegionId!: number;

    @Input()
    public addEditType!: AddEditType;

    @Input()
    public save = (saveDesign: ISaveDesign) => this.checkZoneAndSave(saveDesign);

    @Input()
    public submitted!: boolean;

    public methodInputsData!: IMethodInputsData;
    public unitInputsData!: IUnitlInputsData;

    public collapseRegion!: {
        MethodAndApprovals: boolean;
        UnitsAndParams: boolean;
    };

    public requiredValidator = Validators.required;
    public isEditLoaded = false;

    private appSettingsHelper: AppSettingsHelper;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private calculationService: CalculationService,
        private userService: UserService,
        private codeList: CodeListService,
        private logger: LoggerService,
        private commonCodeListService: CommonCodeListService,
        private numberService: NumberService,
        private ngZone: NgZone) {
        this.appSettingsHelper = new AppSettingsHelper(this.localization, this.userSettings, this.codeList, this.commonCodeListService, this.numberService);
    }

    ngOnInit(): void {
        this.collapseRegion = {
            MethodAndApprovals: false,
            UnitsAndParams: false
        };

        this.methodInputsData = {
            addEditType: AddEditType.add,
            designStandard: this.design?.designStandard?.id ?? DesignStandards.ACI,
            designMethod: this.design?.designMethodGroup?.id ?? DesignMethodGroups.ACI31819
        };

        this.unitInputsData = {
            addEditType: AddEditType.add,
            length: Unit.mm,
            area: Unit.mm2,
            stress: Unit.N_mm,
            force: Unit.kN,
            moment: Unit.kNm,
            temperature: Unit.C,
            forcePerLength: Unit.kN_m,
            momentPerLength: Unit.kNm_m,
            density: Unit.kg_m3
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.isEditLoaded && changes['addEditType']?.currentValue == AddEditType.edit) {
            this.methodInputsData = {
                addEditType: AddEditType.edit,
                designStandard: this.design?.designStandard?.id,
                designMethod: this.design?.designMethodGroup?.id
            };

            // inject unit inputs data
            this.unitInputsData = {
                addEditType: AddEditType.edit,
                length: this.design?.unitLength,
                area: this.design?.unitArea,
                stress: this.design?.unitStress,
                force: this.design?.unitForce,
                moment: this.design?.unitMoment,
                temperature: this.design?.unitTemperature,
                forcePerLength: this.design?.unitForcePerLength,
                momentPerLength: this.design?.unitMomentPerLength,
                density: this.design?.unitDensity,
                basePlateFactor: this.design?.basePlateFactor,
                safetyFactorPermLoad: this.design?.safetyFactorPermLoad,
                safetyFactorVarLoad: this.design?.safetyFactorVarLoad,
                minAnchorProfileDist: this.design?.minAnchorProfileDist,
                minConcreteCover: this.design?.minConcreteCover,
                concreteSafetyFactorGammaC: this.design?.concreteSafetyFactorGammaC
            };

            this.isEditLoaded = true;
        }
    }

    public get design() {
        return this.userService.design;
    }

    private checkZoneAndSave(saveDesign: ISaveDesign) {
        // This save method is called from pe-ui and also from pe-ui-cw, we have to check manually if we have to run it in a zone
        if (NgZone.isInAngularZone()) {
            return this.saveInternal(saveDesign);
        }
        else {
            return this.ngZone.run(() => {
                return this.saveInternal(saveDesign);
            });
        }
    }

    private async saveInternal(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        let calcResult: ICalculationResult | undefined = undefined;
        let design: Design;

        try {
            switch (this.addEditType) {
                case AddEditType.add:
                    calcResult = await this.createNewDesign(saveDesign);
                    design = calcResult?.design;
                    break;
                case AddEditType.edit:
                    design = this.design;
                    await this.editDesign(saveDesign, design);
                    break;
                default:
                    throw new Error(`AddEditType ${this.addEditType} not implemented!`);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.logger.log(err.message, LogType.error);
            }
            throw err;
        }

        return {
            designId: design.id,
            path: `${Constants.DesignPath}/`,
            design: design,
            success: true
        };
    }

    private async createNewDesign(saveDesign: ISaveDesign) {
        const designRequest = {
            regionId: this.selectedRegionId,
            designStandard: this.methodInputsData.designStandard as unknown as DesignStandards,
            designMethodGroup: this.methodInputsData.designMethod as unknown as DesignMethodGroups,
            designSubType: this.designInfo?.designSubType ?? DesignSubTypes.Facade,
            applicationType: ApplicationTypes.None,
            projectId: saveDesign.projectId,
            projectName: saveDesign.projectName,
            designName: saveDesign.designName,
            language: this.userSettings.getLanguage().culture,
            numberDecimalSeparator: this.userSettings.getDecimalSeparator().character,
            numberThousandsSeparator: this.userSettings.getThousandsSeparator().character,
            unitLength: toCwUnit(this.unitInputsData.length ?? Unit.mm),
            unitArea: toCwUnit(this.unitInputsData.area ?? Unit.mm2),
            unitStress: toCwUnit(this.unitInputsData.stress ?? Unit.N_mm),
            unitForce: toCwUnit(this.unitInputsData.force ?? Unit.kN),
            unitMoment: toCwUnit(this.unitInputsData.moment ?? Unit.kNm),
            unitTemperature: toCwUnit(this.unitInputsData.temperature ?? Unit.C),
            unitForcePerLength: toCwUnit(this.unitInputsData.forcePerLength ?? Unit.kN_m),
            unitMomentPerLength: toCwUnit(this.unitInputsData.momentPerLength ?? Unit.kNm_m),
            unitDensity: toCwUnit(this.unitInputsData.density ?? Unit.kg_m3),
            basePlateFactor: this.unitInputsData.basePlateFactor,
            safetyFactorPermLoad: this.unitInputsData.safetyFactorPermLoad,
            safetyFactorVarLoad: this.unitInputsData.safetyFactorVarLoad,
            minAnchorProfileDist: this.unitInputsData.minAnchorProfileDist,
            minConcreteCover: this.unitInputsData.minConcreteCover,
            concreteSafetyFactorGammaC: this.unitInputsData.concreteSafetyFactorGammaC,
            forceFreeLicense: this.userSettings.settings.application.general.forceFreeLicense.value ?? false
        } as NewDesignRequest;

        return await this.calculationService.createNewDesign(designRequest);
    }

    private async editDesign(saveDesign: ISaveDesign, design: Design) {
        design.designName = saveDesign.designName;

        if (this.methodInputsData.designStandard != null) {
            design.designStandard = this.appSettingsHelper.getDesignStandardById(this.methodInputsData.designStandard);
        }

        if (this.methodInputsData.designMethod != null) {
            design.designMethodGroup = this.appSettingsHelper.getDesignMethodById(this.methodInputsData.designMethod);
        }

        design.unitLength = this.unitInputsData.length ?? Unit.mm;
        design.unitArea = this.unitInputsData.area ?? Unit.mm2;
        design.unitStress = this.unitInputsData.stress ?? Unit.N_mm;
        design.unitForce = this.unitInputsData.force ?? Unit.kN;
        design.unitMoment = this.unitInputsData.moment ?? Unit.kNm;
        design.unitTemperature = this.unitInputsData.temperature ?? Unit.C;
        design.unitForcePerLength = this.unitInputsData.forcePerLength ?? Unit.kN_m;
        design.unitMomentPerLength = this.unitInputsData.momentPerLength ?? Unit.kNm_m;
        design.unitDensity = this.unitInputsData.density ?? Unit.kg_m3;
        design.basePlateFactor = this.unitInputsData.basePlateFactor;
        design.safetyFactorPermLoad = this.unitInputsData.safetyFactorPermLoad;
        design.safetyFactorVarLoad = this.unitInputsData.safetyFactorVarLoad;
        design.minAnchorProfileDist = this.unitInputsData.minAnchorProfileDist;
        design.minConcreteCover = this.unitInputsData.minConcreteCover;
        design.concreteSafetyFactorGammaC = this.unitInputsData.concreteSafetyFactorGammaC;

        await this.calculationService.calculateAsync(design);
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}
