<pe-modal-header [title]="title" [modalInstance]="modalInstance">
</pe-modal-header>

<form>
    <pe-modal-body>
        <div class="section">
            <div class="section-row">
                <div class="section-row-margin">
                    <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.TextOne"></span>
                </div>
            </div>
            <div class="section-row">
                <div class="section-row-margin">
                    <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.List"></span>
                </div>
            </div>
            <div class="section-row-margin">
                <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.TextTwo"></span>
            </div>
        </div>

        <div class="section">
            <div class="section-row">
                <div class="fastening-technology-image flexcontainer">
                    <img class="product-image" src="{{images.castinAnchorChannels}}" alt="Cast-in anchor channels">
                    <div>
                        <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.Section.AnchorChannels"></span>
                    </div>
                </div>
            </div>

            <div class="section-row">
                <div class="fastening-technology-image flexcontainer">
                    <img class="product-image" src="{{images.postInstalledAnchors}}" alt="Post installed anchors">
                    <div>
                        <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.Section.PostInstallAnchors"></span>
                    </div>
                </div>
            </div>

            <div class="section-row">
                <div class="fastening-technology-image flexcontainer">
                    <img class="product-image" src="{{images.embeddedPlates}}" alt="Embedded plates">
                    <div>
                        <span l10n="Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnology.Section.CastInAnchors"></span>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button id="cw-fastening-technology-ok-button" look="Primary" class="modal-button" (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Ok')" [disabled]="submitted">
        </pe-button>
    </pe-modal-footer>
</form>