import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import { AvailabilityRegionLinks } from '../../entities/code-lists/availability-region-links';
import { TranslationFormatService } from '../../services/translation-format.service';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

export interface IShowFullPortfolioPopupComponentData {
  guideLink: string;
  technicalContact: string;
  supplyContact: string;
}

@Component({
  templateUrl: './show-full-portfolio-popup.component.html',
  styleUrls: ['./show-full-portfolio-popup.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ShowFullPortfolioPopupComponent implements OnInit {
    public infoData: AvailabilityRegionLinks | undefined;

    @Input()
    public modalInstance!: ModalInstance<() => AvailabilityRegionLinks | undefined>;

    public submitted = false;

    constructor(
      private localizationService: LocalizationService,
      private translationFormatService: TranslationFormatService
    ) { }

    ngOnInit(): void {
      if (this.modalInstance?.input) {
        this.infoData = this.modalInstance.input();
      }
    }

    public translate(key: string) {
      return this.localizationService.getString(key);
    }

    public close() {
      this.modalInstance.close();
    }

    public translateContact(translationKey: string, replacementKey: string, value: string) {
      return formatKeyValue(this.translate(translationKey), { [replacementKey]: value });
    }

    public get infoLink() {
      return this.infoData?.infoLink;
    }

    public get technicalContact() {
      return this.infoData?.technicalContact;
    }

    public get supplyContact() {
      return this.infoData?.supplyContact;
    }
}
