import { CodeList } from '../code-list';
import { AvailabilityRegionLinkEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class AvailabilityRegionLinks extends CodeList {
    public infoLink?: string;
    public regionId?: number;
    public supplyContact?: string;
    public technicalContact?: string;

    constructor(codeList: any) {
        super(codeList);

        if (codeList != null) {
            this.infoLink = codeList.infoLink;
            this.regionId = codeList.regionId;
            this.supplyContact = codeList.supplyContact;
            this.technicalContact = codeList.technicalContact;
        }
    }

    public static fromService(codeList: AvailabilityRegionLinkEntity) {
        return new AvailabilityRegionLinks(CodeList.baseFromService('AvailabilityRegionLinkEntity', codeList, {
            infoLink: codeList.infoLink,
            regionId: codeList.regionId,
            supplyContact: codeList.supplyContact,
            technicalContact: codeList.technicalContact
        } as AvailabilityRegionLinks));
    }

    public override cloneDeep(): AvailabilityRegionLinks {
        return super.cloneDeep() as AvailabilityRegionLinks;
    }
}
