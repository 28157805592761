export type MainMenuCommand =
    'OpenApproval' |
    'OpenAnchorReinforcement' |
    'OpenFasteningTechnology' |
    'OpenPlateStandoffPopup' |
    'OpenInspectionTypePopup' |
    'OpenTorquingTypePopup' |
    'OpenInstallationFillHolesPopup' |
    'OpenShowFullPortfolioPopup';

export enum Command {
    OpenApproval = 1,
    OpenAnchorReinforcement = 2,
    OpenFasteningTechnology = 3,
    OpenPlateStandoffPopup = 4,
    OpenInspectionTypePopup = 5,
    OpenTorquingTypePopup = 6,
    OpenInstallationFillHolesPopup = 7,
    OpenShowFullPortfolioPopup = 8,
}

const commandDictionary: { [id: number]: MainMenuCommand } = {
    [Command.OpenApproval]: 'OpenApproval',
    [Command.OpenAnchorReinforcement]: 'OpenAnchorReinforcement',
    [Command.OpenFasteningTechnology]: 'OpenFasteningTechnology',
    [Command.OpenPlateStandoffPopup]: 'OpenPlateStandoffPopup',
    [Command.OpenInspectionTypePopup]: 'OpenInspectionTypePopup',
    [Command.OpenTorquingTypePopup]: 'OpenTorquingTypePopup',
    [Command.OpenInstallationFillHolesPopup]: 'OpenInstallationFillHolesPopup',
    [Command.OpenShowFullPortfolioPopup]: 'OpenShowFullPortfolioPopup',
};

export function commandFromService(command: Command): MainMenuCommand {
    return commandDictionary[command];
}